<template>
  <div class="accounts__page">
    <Redirect class="" title="ACCOUNT" route="website.settings.account" />
    <v-row no-gutters>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">Add staff account</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters class="py-4">
      <v-col cols="12" sm="4" class="">
        <h3>Staff accounts</h3>
        <div>Give staff access to your store by sending them an invitation</div>
      </v-col>
      <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column ">
        <Notification v-if="errors && errors.length > 0" :messages="errors" type="error" />

        <InputDefault :model="['email']" fieldName="Email" label="Email" :isValidate="true" />
        <v-divider class="my-5"></v-divider>
        <v-checkbox label="This staff account will have full permissions" v-model="isStoreAdmin"> </v-checkbox>
        <div v-if="!isStoreAdmin" cols="12">
          <SelectPermission />
        </div>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn color="error" class="mr-3" @click="onCancel">Cancel</v-btn>
        <v-btn :loading="isLoading" color="primary" @click="onSubmit">Send invite</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Redirect from '@/components/RedirectTo';
import { mapState, mapGetters } from 'vuex';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import SelectPermission from './SelectPermission';
import { authService } from '@/apis/permission';

export default {
  components: {
    Redirect,
    SelectPermission,
  },
  data() {
    return {
      email: '',
      errors: [],
      permissions: [],
      isStoreAdmin: true,
      listPermissions: [],
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      this.isLoading = true;
      if (!this.$v.$invalid) {
        try {
          let payload = {
            email: this.email,
            isStoreAdmin: this.isStoreAdmin,
          };
          if (!this.isStoreAdmin) payload.permissions = this.permissions;
          let res = await authService.addPermissionStoreStaff(payload);
          this.$router.push({ name: 'website.settings.account.update', params: { id: res.data.id } });
        } catch (error) {
          this.errors = error;
        }
      }
      this.isLoading = false;
    },
    onCancel() {
      this.$router.push({ name: 'website.settings.account' });
    },
    async getPermission() {
      try {
        let res = await authService.getAllPermission();
        this.listPermissions = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getPermission();
  },
};
</script>
<style lang="scss"></style>
